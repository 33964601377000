import React from 'react';
import BottomButton from './BottomButton';
import IcomoonIcon from '../../shared/IcomoonIcon';

const Burger = (props) => (
  <BottomButton {...props}>
    <IcomoonIcon
      icon={props.open ? 'burger-opened' : 'burger-closed'}
      color="rgb(45, 165, 254)"
      className=""
      size={40}
    />
    <span>เมนู</span>
  </BottomButton>
);

export default Burger;
