import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { color, breakpoints, font } from '../../utils/style';
import { validateLink } from '../../utils/system';
import IcomoonIcon from './IcomoonIcon';

const SubMenu = styled('ul')`
  position: absolute;
  z-index: 999;
  display: none;
  text-align: left;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  background-color: ${color.purple};
  transition: all 0.2s ease-in-out;
  margin: 0;
  padding: 0;
  .submenu-item {
    line-height: 2;
    font-weight: ${font.normalWeight};
    a {
      &:active,
      &:hover {
        border-bottom: none;
      }
      .active {
        border-bottom: none;
      }
    }
  }
  li:last-child:before {
    display: none;
  }
  @media (max-width: ${breakpoints.md}) {
    display: none;
    position: relative;
    left: -12%;
    background-color: unset;
    margin-left: 30px;
    padding: 0;
    .submenu-item {
      padding-left: 0;
      margin-top: 20px;
      height: 20px;
    }
  }
`;

const SubMenuComp = ({ wordpress_children }) => (
  <SubMenu className="sub-menu">
    {wordpress_children?.map(({ wordpress_id, classes, attr, xfn, title, description, path }) => (
      <li key={wordpress_id} className={`submenu-item ${classes}`}>
        <Link activeClassName="active-sub" to={validateLink(path.replace('category/', ''))} replace>
          {attr && (
            <div className="subitem-arrow">
              {attr === 'bakkarat' && classes ? (
                <IcomoonIcon icon="play" color={color.white} size={16} alt="submenu" />
              ) : (
                <IcomoonIcon icon="arrow-right" color={color.white} size={16} alt="submenu" />
              )}
            </div>
          )}
          <span className="subitem-title">
            {title}
            {xfn && <span className="subitem-label">{xfn}</span>}
          </span>
          {description && <span className="subitem-description">{description}</span>}
        </Link>
      </li>
    ))}
  </SubMenu>
);

export default SubMenuComp;
