import React from 'react';
import styled from '@emotion/styled';
import { useModalOpen } from '../shared/Modal';
import { color } from '../../utils/style';
import { mq, spreadProps } from '../../utils/mediaQuery';

const LoginBtn = styled.div`
  height: 44px;
  width: 115px;
  border: none;
  background: ${color.darkPurple};
  text-transform: capitalize;
  transition-duration: 0.4s;
  margin-right: 16px;
  padding: 10px 0;
  border-radius: 4px;
  cursor: pointer;
  ${mq('md')} {
    width: 50%;
    margin-right: 0;
    background-color: transparent;
    border: 1px solid white;
  }

  :active,
  :hover {
    background: #1a2559;
    border: none;
  }
  ${(props) => spreadProps(props.styleProps)}
`;

const LoginButton = ({ className }) => {
  const clickHandler = useModalOpen('login-popup-window');

  return (
    <LoginBtn onClick={clickHandler} className={className}>
      <span>เข้าสู่ระบบ</span>
    </LoginBtn>
  );
};

export default LoginButton;
