import React, { useState, useEffect, useContext, useMemo } from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import styled from '@emotion/styled';
import { font, color, breakpoints, border, sizing, area } from '../../utils/style';
import SubMenu from '../shared/SubMenu';
import BtnChildMenu from './BtnChildMenu';
import Auth from '../Auth/Auth';
import { validateLink } from '../../utils/system';
import StoreContext from '../shared/Context/StoreContext';

const NavWrap = styled('div')`
  height: 60px;
  border-radius: ${border.borderRadius};
  background-color: ${color.darkPurple};
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 2;
  left: 0;
  margin-bottom: ${area.betweenBlocks};
  .register-login-btns {
    display: none;
  }
  @media (max-width: ${breakpoints.md}) {
    height: 100vh;
    width: 100%;
    //left: -400px;
    top: 100%;
    position: absolute;
    z-index: 1;
    display: none;
    &.open {
      display: block;
      > ul {
        display: block;
      }
    }
    background-color: #141d44;
    .register-login-btns {
      display: flex;
      height: 80px;
      margin-bottom: 80px;
      margin-top: 0;
    }
  }
`;

const Nav = styled('ul')`
  height: 100%;
  width: 100%;
  max-width: 1232px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${font.default};
  font-weight: ${font.headingWeight};
  border-radius: ${border.borderRadius};
  list-style: none;
  text-align: center;
  font-size: ${sizing.default};
  opacity: 1;
  text-transform: uppercase;
  li {
    position: relative;
    margin-top: 0;
    display: inline-block;
    width: 100%;
    vertical-align: bottom;
    white-space: nowrap;
    line-height: 3.9;
    cursor: pointer;
    box-sizing: border-box;
    height: 100%;
    .btn-open svg {
      transform: rotate(0);
      &:hover {
        transform: none;
      }
    }
    &:hover {
      .sub-menu {
        display: flex;
        padding: 8px 16px;
        width: 100%;
      }
      .btn-child-menu svg {
        transform: rotate(0deg);
      }
    }
    &.active {
      border-bottom: 3px solid ${color.pink};
      .mobile-menu-link {
        &:after {
          height: 0;
        }
      }
    }
  }
  a {
    height: 100%;
    display: block;
    color: ${color.lightGrey};
    box-sizing: border-box;
    &:after {
      content: '';
      height: 3px;
      width: 0;
      display: block;
      background-color: ${color.pink};
      transition: all 0.3s ease-in-out;
      position: absolute;
      left: 50%;
      bottom: 0;
    }
    @media (max-width: ${breakpoints.md}) {
      font-family: ${font.default};
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.63;
      letter-spacing: normal;
      color: #d4daf8;
    }
  }

  a:hover,
  a:active,
  .active {
    text-decoration: none;
    color: ${color.white};
    &:after {
      width: 100%;
      left: 0;
    }
    .active-sub {
      border-bottom: 3px solid ${color.pink};
    }
  }
  .home {
    width: 200px;
    padding: 0 20px;
    margin: 0 20px;
    position: relative;
    a {
      display: block;
      text-align: center;
    }
  }

  @supports (-ms-ime-align: auto) {
    font-size: 1.125rem;
    .home::before,
    .home::after {
      border-radius: 0;
    }
  }

  @media (max-width: ${breakpoints.lg}) {
    font-size: 0.9rem;
  }

  @media (max-width: ${breakpoints.md}) {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 74px;
    background-color: #141d44;
    margin: 0;
    overflow-y: scroll;
    flex-direction: column;
    display: none;
    z-index: 1;
    padding-top: 25px;
    li {
      width: 100%;
      height: 20px;
      line-height: 3.2;
      text-align: left;
      margin: 25px 0 0 0;
      padding-left: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      &:first-of-type {
        margin-top: 0;
      }
      & > a {
        height: max-content;
        font-family: ${font.default};
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.63;
        letter-spacing: normal;
        color: #d4daf8;
        text-transform: uppercase;
        &:active,
        &:hover {
          color: #d4daf8;
        }
        &::after {
          display: none;
        }
      }
      &.active {
        text-decoration: none;
        border-bottom: none;
        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 20px;
          background-color: #ff3952;
        }
        .mobile-menu-link {
          color: ${color.white};
        }
        .active-sub {
          color: ${color.white};
          position: relative;
          border-bottom: none;
          &:before {
            content: '';
            position: absolute;
            top: 11px;
            left: -43px;
            width: 3px;
            height: 20px;
            background-color: #ff3952;
          }
        }
      }
      & .btn-mobile {
        & > div > svg {
          transform: rotate(90deg);
        }
      }
      & .btn-open {
        & > div > svg {
          transform: rotate(0);
        }
      }
      &.li-open {
        height: max-content;
        & > a {
          margin-top: -10px;
        }
        .sub-menu {
          display: flex;
          padding: 0 0 0 16px;
          width: 130%;
          top: 100%;
          font-size: inherit;
          margin-top: 10px;
        }
      }
    }
    &.open {
      display: block;
    }
    .home {
      display: block;
      position: absolute;
      right: 50%;
      width: 250px;
      padding: 0;
      margin-right: -125px;
      bottom: 100%;
      background: none;
      &::after,
      &::before {
        display: none;
      }
      div {
        background: none;
        padding: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .home {
      margin-right: 0;
      right: 10%;
    }
  }

  @media (max-width: ${breakpoints.xxs}) {
    .home {
      right: 10px;
    }
  }
`;

const MainWrapper = styled.div`
  display: none;

  @media (max-width: ${breakpoints.md}) {
    display: block;
    margin-top: 30px;
    padding: 0 16px;
    width: 100vw;
    left: 0;
    position: absolute;
    & .login-button {
      border-radius: 8px;
      border-style: solid;
      border-width: 1px;
      border-color: #ff3952;
      color: #ff3952;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 49%;
    }
    & .register-button {
      border-radius: 8px;
      background-color: #ff3952;
      margin-left: 0;
      width: 49%;
      color: ${color.white};
      &:hover,
      &:active,
      &:focus {
        color: #ffffff;
      }
    }
  }
`;

// REFACTOR.
// If it's gonna be enough time.

export default () => {
  const [open, setOpen] = useState('');
  const [isMobile, setMobile] = useState(false);
  const [location, setLocation] = useState({});
  const context = useContext(StoreContext);

  useEffect(() => {
    if (window) {
      setMobile(window.outerWidth < 960);
      setLocation(window.location);
    }
  }, []);

  const MobMenu = useMemo(
    () => (
      <MainWrapper>
        <Auth
          styleProps={{
            display: 'flex',
            position: 'initial',
            padding: '0 0px',
            justifyContent: 'space-between',
            flexDirection: 'row-reverse',
          }}
          balanceStyle={{
            width: '50% !important',
            maxWidth: '50%',
            padding: '0 15%',
            textAlign: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            marginRight: '0',
          }}
          userAccountStyle={{
            width: 'calc(50% - 16px) !important',
            padding: '10px 0',
            textAlign: 'center',
            alignItems: 'center',
            whiteSpace: 'nowrap',
            marginLeft: '8px',
          }}
          LoginBtnProps={{ width: '50% !important', marginRight: '0' }}
          RegisterBtnProps={{ width: 'calc(50% - 16px) !important' }}
        />
      </MainWrapper>
    ),
    [isMobile],
  );

  const isActiveTab = (link) => {
    if (!context.postCategory) return location.pathname?.includes(validateLink(link));
    if (!context.postCategory.path) return false;
    return validateLink(link) === context.postCategory.path.replace('category/', '');
  };

  const choseSlug = (slug) => {
    const obj = {
      'category/articles': () => '/articles/',
      '%e0%b8%82%e0%b9%88%e0%b8%b2%e0%b8%a7%e0%b8%9f%e0%b8%b8%e0%b8%95%e0%b8%9a%e0%b8%ad%e0%b8%a5': () =>
        '/football-news/',
      rest: `/${slug}/`,
    };
    return obj[slug] ? obj[slug]() : obj.rest;
  };

  const isCurrentOpen = (link) => open === link;

  const chooseSubMenu = (link) => {
    setOpen(link === open ? '' : link);
  };

  return (
    <StaticQuery
      query={graphql`
        {
          allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "main" } }) {
            nodes {
              id
              name
              slug
              items {
                title
                classes
                attr
                object_slug
                path
                target
                url
                wordpress_children {
                  attr
                  classes
                  description
                  path
                  title
                  xfn
                  object_slug
                  wordpress_id
                  target
                  url
                }
              }
            }
          }
        }
      `}
      render={({ allWordpressWpApiMenusMenusItems: { nodes } }) => (
        <NavWrap id="nav">
          <Nav>
            {nodes[0].items?.map(({ object_slug, type_label, wordpress_children, path, title }) => {
              const rightUrl = (path.indexOf('/') === path.lastIndexOf('/') ? '/' : '') + path.replace(/category/i, '');
              return (
                <li
                  className={
                    ` ${object_slug === '!home' ? 'home' : ''}` +
                    ` ${isActiveTab(rightUrl) ? 'active' : ''}` +
                    ` ${isCurrentOpen(rightUrl) ? 'li-open' : ''}`
                  }
                  id={choseSlug(object_slug)}
                  key={type_label + object_slug}
                >
                  <Link to={validateLink(rightUrl)} className="mobile-menu-link">
                    {title}
                  </Link>
                  {wordpress_children && (
                    <div
                      className={isCurrentOpen(rightUrl) ? 'btn-open' : 'btn-mobile'}
                      onClick={() => chooseSubMenu(rightUrl)}
                    >
                      <BtnChildMenu />
                      {isCurrentOpen(rightUrl) && <SubMenu wordpress_children={wordpress_children} />}
                    </div>
                  )}
                </li>
              );
            })}
            <li className="register-login-btns">{MobMenu}</li>
          </Nav>
        </NavWrap>
      )}
    />
  );
};
