import React, { useContext } from 'react';
import styled from '@emotion/styled';
import {
  setAuthToken,
  getAuthToken,
  getAuthUserName,
  resetAuthToken,
  resetAuthUserName,
  hasAuthToken,
  Logout,
  activationUser,
} from './Utils';
import LoginForm, { LoginFormOpen } from './Form/LoginForm';
import WithdrawForm from '../Content/Withdraw/Form';
import { StoreContext } from '../../store';
import { mq, spreadProps } from '../../utils/mediaQuery';
import LoginButton from './LoginButton';
import RegisterButton from './RegisterButton';
import HeaderProfilePanel from './HeaderProfilePanel';

const MainWrapper = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  display: flex;
  text-align: center;
  align-items: center;

  ${mq('md')} {
    display: none;
    left: 0;
    padding: 0 16px;
    top: 0;
    ${(props) => spreadProps(props.styleProps)}
  }
`;

const Auth = ({ styleProps, LoginBtnProps, RegisterBtnProps }) => {
  const [state] = useContext(StoreContext);
  const { user: { userName } } = state;

  return userName ? (
    <MainWrapper styleProps={styleProps}>
      <HeaderProfilePanel />
      <WithdrawForm popupId="withdraw-popup-window" />
    </MainWrapper>
  ) : (
    <>
      <MainWrapper styleProps={styleProps}>
        <LoginButton styleProps={LoginBtnProps} className="login-button" />
        <RegisterButton styleProps={RegisterBtnProps} className="register-button" />
      </MainWrapper>
      <LoginForm popupId="login-popup-window" />
    </>
  );
};

export {
  getAuthToken,
  getAuthUserName,
  resetAuthToken,
  resetAuthUserName,
  hasAuthToken,
  setAuthToken,
  activationUser,
  LoginFormOpen,
  Logout,
};
export default Auth;
