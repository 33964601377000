import {
  // PREDICTIONS TYPES
  predictionsBets,
  predictionsError,
  predictionRoundsLoaded,
  predictionLeaderBoardLoaded,
  predictionHistoryLoaded,
  predictionStartRoundsLoad,
  predictionStartHistoryLoad,
  predictionStartLeaderBoardLoad,
  predictionSetCurrentLeagueId,
  predictionsOnSubmit as predOnClick,
  removeTeamBet,
  togglePredictionsLeague,
  predictionAcceptedType,
  predictionSetCurrentLeagueType,
  predictionClearData,
  predictionsTotalPoints,
  // APPLICATION TYPES
  applicationIsLoaded,
  currentEnvIsDev,
  setApplicationError,
  setIsMobileMode,
  // USER'S TYPES
  setUserName,
  loadBalance,
  // PROFILE TYPES
  setProfileMenuTab,
  loadBonuses,
  setProfileWiningBonusesType,
  setProfileBetsType,
  setSubTab,
  setBetsFormatted,
  popupClearAll,
  popupRemove,
  popupSetNew,
  popupShow,
  popupHide,
  setUserAvatarUploaded,
  changeUserAvatar,
} from './types';
// PREDICTIONS CONSTANT'S
import { urlLeaderBoard, urlHistory } from '../utils/constants';
import { predictionRounds } from '../utils/api/prediction';
import { freespinGetAvatar } from '../utils/api/freespin';

// PREDICTIONS ACTIONS
export const setPredictionsTotalPoints = (dispatch, points) =>
  dispatch({ type: predictionsTotalPoints, payload: points });
export const setBetOnMatch = (dispatch, matchBets, roundId, matchId, winMode) => {
  const round = matchBets ? { ...matchBets[roundId] } : {};
  round[matchId] = winMode;
  return dispatch({ type: predictionsBets, payload: { roundId, round } });
};
export const toggleLeague = (dispatch) => dispatch({ type: togglePredictionsLeague });
export const removePredictionsTeam = (dispatch, matchBets, roundId, matchId) => {
  const matches = matchBets;
  delete matches[roundId][matchId];
  if (Object.keys(matches[roundId]).length === 0) delete matches[roundId];
  return dispatch({ type: removeTeamBet, payload: matches });
};
export const setPredictionsError = (dispatch, matchId, error) =>
  dispatch({ type: predictionsError, payload: { matchId, error } });
export const setCurrPredictionsLeagueId = (dispatch, id) =>
  dispatch({ type: predictionSetCurrentLeagueId, payload: id });
export const predictionSetCurrentLeague = (dispatch, rounds, id) => {
  const leagues = rounds.filter(({ id: leagueId }) => id === leagueId);
  return dispatch({
    type: predictionSetCurrentLeagueType,
    payload: leagues.length === 0 ? {} : leagues[0],
  });
};
export const loadPredictionsRounds = (dispatch) => async () => {
  dispatch({ type: predictionStartRoundsLoad });
  predictionRounds().then((json) => {
    if (!json) return false;
    dispatch({ type: predictionRoundsLoaded, payload: json.reverse() });
  });
};
export const loadPredictionsHistory = (dispatch) => async (id) => {
  dispatch({ type: predictionStartHistoryLoad });
  setCurrPredictionsLeagueId(dispatch, id);
  const req = fetch(urlHistory + id).then((historyData) => historyData.json());
  const payload = await req;
  if (payload) dispatch({ type: predictionHistoryLoaded, payload });
};
export const loadPredictionsLeaderBoards = (dispatch) => async (id) => {
  dispatch({ type: predictionStartLeaderBoardLoad });
  const req = fetch(urlLeaderBoard + id).then((leaderData) => leaderData.json());
  const payload = await req;
  if (payload) dispatch({ type: predictionLeaderBoardLoaded, payload });
};
export const predictionsOnSubmit = (dispatch) => async () => dispatch({ type: predOnClick });
export const predictionSetAccepted = (dispatch, mode) => dispatch({ type: predictionAcceptedType, payload: mode });
export const clearPredictionData = (dispatch) => dispatch({ type: predictionClearData });

// APPLICATION ACTIONS
export const appIsLoaded = (dispatch) => dispatch({ type: applicationIsLoaded });
export const currEnvIsDev = (dispatch, envIsDev) => dispatch({ type: currentEnvIsDev, payload: envIsDev });
export const setAppError = (dispatch, error) => dispatch({ type: setApplicationError, payload: error });
export const setIsMobile = (dispatch, mode) => dispatch({ type: setIsMobileMode, payload: mode });

// USER'S ACTIONS
export const setUser = (dispatch, setUser) => dispatch({ type: setUserName, payload: setUser });
export const setBalance = (dispatch, balance) => dispatch({ type: loadBalance, payload: balance });
export const setBonuses = (dispatch, bonuses) => dispatch({ type: loadBonuses, payload: bonuses });

// PROFILE ACTIONS
export const setProfileMenu = (dispatch, menuKey) => dispatch({ type: setProfileMenuTab, payload: menuKey });
export const setProfileWiningBonuses = (dispatch, bonuses) =>
  dispatch({
    type: setProfileWiningBonusesType,
    payload: bonuses,
  });
export const setActiveSubTab = (dispatch, tab) => dispatch({ type: setSubTab, payload: tab });
export const formatBets = (dispatch, bets) => {
  const betsData = { totalPoints: 0 };
  const formattedBets = bets.reduce((obj, bet) => {
    if (typeof bet.points === 'number' || typeof bet.points === 'string') {
      betsData.totalPoints += +bet.points;
    }

    return { ...obj, [bet.match_id]: { ...bet } };
  }, {});
  setPredictionsTotalPoints(dispatch, betsData.totalPoints);
  return dispatch({ type: setBetsFormatted, payload: formattedBets });
};
export const setProfileBets = (dispatch, bets) => {
  formatBets(dispatch, bets);
  return dispatch({ type: setProfileBetsType, payload: bets });
};

export const setUserAvatar = (dispatch) =>
  // console.log('run setUserAvavatar');
  // dispatch({ type: setUserAvatarUploaded, payload: avatar })
  freespinGetAvatar()
    .then((response) => dispatch({ type: setUserAvatarUploaded, payload: response.result }))
    .catch((error) => new Error(error));

export const uploadUserAvatar = () => ({ type: changeUserAvatar });

// POPUP
export const clearAllPopups = (dispatch) => dispatch({ type: popupClearAll });
export const removePopup = (dispatch, popup, id) => {
  delete popup[id];
  return dispatch({ type: popupRemove, payload: popup });
};
export const setNewPopup = (dispatch, id) => dispatch({ type: popupSetNew, payload: { [id]: false } });
export const showPopup = (dispatch, id) => dispatch({ type: popupShow, payload: { [id]: true } });
export const hidePopup = (dispatch, id) => dispatch({ type: popupHide, payload: { [id]: false } });
