import React from 'react';
import styled from '@emotion/styled';
import { color, font, resetButton, sizing } from '../../utils/style';

const Wrap = styled.button`
  ${resetButton};
  max-width: 85px;
  width: 100%;
  padding: 5px 0px;
  border-radius: 4px;
  box-shadow: -20px 20px 14px 0 rgba(189, 66, 250, 0);
  background-color: ${color.darkPurple};
  
  color: ${color.white};
  font-family: ${font.default};
  font-size: ${sizing.small};
  font-weight: ${font.medium};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;  
 
  & span {
    margin-left: 10px;    
  }
  
  &:hover {
    background-color: ${color.activeBlue};
  }
  
  &:active {
    background-color: ${color.activeBlue};
  }
`;

const ProfileButton = ({ children, className = '', onClick = null }) => (
  <Wrap className={className} onClick={onClick}>{children}</Wrap>
);

export default ProfileButton;
