import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import IcomoonIcon from './IcomoonIcon';
import { breakpoints, color } from '../../utils/style';

const Wrap = styled('div')`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 999999;
  overflow: hidden;
  display: none;
  align-items: center;
  justify-content: center;
  
  // Withdraw modifier
  &#withdraw-popup-id {
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  
    & > div {
      width: 80%;
      min-height: 93%;
      
      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    &#withdraw-popup-id {
      overflow-y: scroll;
      align-items: flex-start;
      padding: 30px 0;
      & > div {
        width: 90%;
        max-height: max-content;
      }
    }
  }
`;

const Popup = styled('div')`
  width: 30%;
  max-height: 80%;
  margin: 0 auto 0 auto!important;
  left: calc(50% - 250px);
  z-index: 9999;
  padding: 30px;
  background: #10192e;
  border-radius: 8px;
  overflow-y: auto;
  background-size: 100% 100%;
  
  .h3 {
    margin: 0;
  }
  p {
    margin: 10px 0;
  }
  .popup-content {
    overflow: hidden;
    margin-top: 40px;
  }
  &.popup-open {
    display: block;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    padding: 30px 16px;
    width: 300px;
    margin-left: 0px;
    left: calc(50% - 150px);
    &#free-spin-home-popup {
      left: calc(50% - 150px);
      width: 300px;
      height: 281px;
    }
  }
`;

const CloseButton = styled('button')`
  background: none;
  border: none;
  outline: none;
  color: #fff;
  position: absolute;
  top: 32px;
  right: 24px;
  &:hover,
  &:active,
  &:focus {
    opacity: 0.7;
    cursor: pointer;
  }
  @media (max-width: ${breakpoints.xs}) {
    top: 25px;
    right: 15px;
  }
`;

const closeHandler = (popupId) => {
  const layout = document.getElementById('all-content');
  const username = document.getElementById('login-username');
  const pass = document.getElementById('login-password');
  if (layout) {
    layout.classList.remove('popup-blocked');
  }
  const popupWindow = document.getElementById(popupId);
  const popWrapper = document.getElementById(`${popupId}-id` || 'popWrapper');
  if (popupWindow) popupWindow.classList.remove('popup-open');
  if (popWrapper) popWrapper.style.display = 'none';
  if (username) username.value = '';
  if (pass) pass.value = '';
  const popup = document.getElementById(popupId);
  const iframe = popup && popup.getElementsByTagName('iframe');
  if (iframe && iframe.length > 0) {
    iframe[0].src = '';
  }
};

// REFACTOR
// Should be full rewrite with new logic
const invokePopup = (event, popupId, id, iframeLink) => {
  if (event) event.preventDefault();

  const layout = document.getElementById('all-content');
  const popWrapper = document.getElementById(`${popupId}-id` || 'popWrapper');
  const popupWindow = document.getElementById(popupId);

  if (layout) layout.classList.add('popup-blocked');
  if (popWrapper) popWrapper.style.display = 'flex';
  if (popupWindow) popupWindow.classList.add('popup-open');

  // console.log('id: ', `${popupId}-id` || 'popWrapper');

  if (iframeLink && popWrapper) {
    const iframe = popWrapper.getElementsByTagName('iframe');
    return iframe.length > 0 ? (iframe[0].src = iframeLink) : null;
  }
  return false;
};

const closePopup = (event, popupId, id) => {
  closeHandler(popupId, id);
};

const PopupWindow = ({ children, popupId, title, id }) => (
  <Wrap id={popupId ? `${popupId}-id` : 'popWrapper'} className="PopWrapper">
    <Popup id={popupId} className="popup-window">
      <CloseButton onClick={() => closeHandler(popupId, id)}>
        <IcomoonIcon icon="close" color={color.white} size={30} />
      </CloseButton>
      {title && <div id={`${popupId}-title`} className="h3" dangerouslySetInnerHTML={{ __html: title }} />}
      <div id={`${popupId}-content`} className="popup-content">
        {children}
      </div>
    </Popup>
  </Wrap>
);

export default PopupWindow;
export { invokePopup, closePopup };

PopupWindow.propTypes = {
  popupId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
