import React from 'react';

export const FreshChatMobileBtnBottom = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
    <g fill="none" fillRule="evenodd">
      <g transform="translate(1 1)">
        <path fill="#21A3FF" d="M22.8699444,4.73333333 C24.5927618,4.73333333 25.9998933,6.0810573 26.0948883,7.78005469 L26.1,7.96338889 L26.1,18.6111111 C26.1,20.3327331 24.7522394,21.7409775 23.054425,21.8360507 L22.8712222,21.8411667 L18.226,21.841 L16.4004775,25.492053 C16.146841,25.9991738 15.5543307,26.2247722 15.034698,26.0316526 L14.9246136,25.9838109 C14.4174928,25.7301744 14.1918944,25.137664 14.3850141,24.6180313 L14.4328558,24.507947 L16.5629114,20.2491136 C16.7285599,19.9179158 17.0478874,19.695161 17.4093967,19.6497487 L17.5467222,19.6411667 L22.8712222,19.6411667 C23.4009772,19.6411667 23.838036,19.2390918 23.893956,18.7232784 L23.9,18.6111111 L23.9,7.96338889 C23.9,7.43202345 23.4986482,6.99524732 22.9822563,6.93937213 L22.8699444,6.93333333 L7.96338889,6.93333333 C7.43202345,6.93333333 6.99524732,7.3346851 6.93937213,7.851077 L6.93333333,7.96338889 L6.93333333,18.6111111 C6.93333333,19.1417911 7.33515832,19.5791613 7.85117165,19.6351187 L7.96338889,19.6411667 L12.2222222,19.6411667 C12.8297354,19.6411667 13.3222222,20.1336534 13.3222222,20.7411667 C13.3222222,21.308179 12.8932115,21.7749905 12.3420793,21.834712 L12.2222222,21.8411667 L7.96338889,21.8411667 C6.24089897,21.8411667 4.83346264,20.4925679 4.73844622,18.7943548 L4.73333333,18.6111111 L4.73333333,7.96338889 C4.73333333,6.24057152 6.0810573,4.83344006 7.78005469,4.73844505 L7.96338889,4.73333333 L22.8699444,4.73333333 Z M19.0366111,0.9 C19.6441243,0.9 20.1366111,1.39248678 20.1366111,2 C20.1366111,2.56701234 19.7076004,3.03382387 19.1564682,3.09354535 L19.0366111,3.1 L4.13005556,3.1 C3.59869012,3.1 3.16191399,3.50135177 3.1060388,4.01774367 L3.1,4.13005556 L3.1,14.7777778 C3.1,15.385291 2.60751322,15.8777778 2,15.8777778 C1.43298766,15.8777778 0.966176132,15.4487671 0.90645465,14.8976349 L0.9,14.7777778 L0.9,4.13005556 C0.9,2.40723819 2.24772396,1.00010673 3.94672135,0.905111715 L4.13005556,0.9 L19.0366111,0.9 Z" />
        <path fill="#FF374E" d="M16.0555556,14.9555556 C16.6630688,14.9555556 17.1555556,15.4480423 17.1555556,16.0555556 C17.1555556,16.6225679 16.7265449,17.0893794 16.1754127,17.1491009 L16.0555556,17.1555556 L10.9444444,17.1555556 C10.3369312,17.1555556 9.84444444,16.6630688 9.84444444,16.0555556 C9.84444444,15.4885432 10.2734551,15.0217317 10.8245873,14.9620102 L10.9444444,14.9555556 L16.0555556,14.9555556 Z M19.8888889,9.84444444 C20.4964021,9.84444444 20.9888889,10.3369312 20.9888889,10.9444444 C20.9888889,11.5114568 20.5598782,11.9782683 20.008746,12.0379898 L19.8888889,12.0444444 L10.9444444,12.0444444 C10.3369312,12.0444444 9.84444444,11.5519577 9.84444444,10.9444444 C9.84444444,10.3774321 10.2734551,9.91062058 10.8245873,9.85089909 L10.9444444,9.84444444 L19.8888889,9.84444444 Z" />
      </g>
      <polygon points="0 0 28 0 28 28 0 28" />
    </g>
  </svg>
);

export default FreshChatMobileBtnBottom;
