import React from 'react';
import styled from '@emotion/styled';
import IcomoonIcon from '../shared/IcomoonIcon';
import { breakpoints, color } from '../../utils/style';

const El = styled('div')`
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  width: 20px;
  
  svg {
    transition: transform 0.2s ease-out;
    transform: rotate(90deg);

    &:hover {
      transform: rotate(0);
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    height: 100%;
    
    & > svg {
      position: absolute;
      top: 5px;
    }
  } 
`;

const BtnChildMenu = () => (
  <El aria-label="ChildMenu" id="icon-triangle">
    <IcomoonIcon icon="play3" color={color.white} size={14} alt="menu" />
  </El>
);

export default BtnChildMenu;
