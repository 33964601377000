import React from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { breakpoints, color } from '../../utils/style';

const WrapperSection = styled('div')`
  padding: 0 16px;
  .wrapper-with-border {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid ${color.borderColor};
    border-bottom: 1px solid ${color.borderColor};
    padding: 30px 0;
  }
  @media (max-width: ${breakpoints.xs}) {
    .wrapper-with-border {
      border-bottom: none;
    }
    .gatsby-image-wrapper {
      width: 144px !important;
      height: 55px !important;
    }
  }
`;

// REFACTOR
// Need to try use object 'arguments' instead of creating new one. If it possible - refactor.
const PaymentSystems = () => (
  <StaticQuery
    query={graphql`
      query {
        royalOnline: file(relativePath: { eq: "RoyalOnline.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        joker: file(relativePath: { eq: "joker.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        mega888: file(relativePath: { eq: "mega888.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        maxbet: file(relativePath: { eq: "maxbet.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        royalHill: file(relativePath: { eq: "royalHill.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        redDragon: file(relativePath: { eq: "redDragon.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        live22: file(relativePath: { eq: "live22.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        pussy888: file(relativePath: { eq: "pussy888.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        royalRuby: file(relativePath: { eq: "royalRuby.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        kiss: file(relativePath: { eq: "kiss.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        goldenSlot: file(relativePath: { eq: "goldenSlot.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        holidayPalace: file(relativePath: { eq: "holidayPalace.png" }) {
          childImageSharp {
            fixed(width: 173) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={({
      royalOnline,
      joker,
      mega888,
      maxbet,
      royalHill,
      redDragon,
      live22,
      pussy888,
      royalRuby,
      kiss,
      goldenSlot,
      holidayPalace,
    }) => {
      const args = [
        royalOnline,
        joker,
        mega888,
        maxbet,
        royalHill,
        redDragon,
        live22,
        pussy888,
        royalRuby,
        kiss,
        goldenSlot,
        holidayPalace,
      ];
      return (
        <WrapperSection>
          <div className="wrapper-with-border">
            {args?.map(
              (img) =>
                img && (
                  <Img
                    key={JSON.stringify(img)}
                    alt="Our Sites"
                    fixed={img.childImageSharp.fixed}
                    fadeIn={false}
                    loading="eager"
                  />
                ),
            )}
          </div>
        </WrapperSection>
      );
    }}
  />
);

export default PaymentSystems;
