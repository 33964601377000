import React from 'react';
import styled from '@emotion/styled';
import { resetButton } from '../../../utils/style';

const Wrapper = styled('a')`
  ${resetButton};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  
  width: 29%;
  height: 100%;
  padding-bottom: 12px;
  
  & > span {
    position: absolute;
    top: 45px;
    text-transform: uppercase;
    color: #ffffff;
    object-fit: contain;
    opacity: 0.7;
    font-family: Kanit;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
  }
  
  & > img {
    margin: 0;
  }
  
  & .online {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #00b901;
    
    position: absolute;
    top: 10px;
    right: 30%;
  }
`;

const BottomButton = ({ children, to, target = '', onClick, rel }) => (
  <Wrapper href={to} target={target} onClick={onClick} rel={rel}>
    {children}
  </Wrapper>
);

export default BottomButton;
