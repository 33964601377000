import React, { useEffect, useContext } from 'react';
import styled from '@emotion/styled';
import { setBonuses } from '../../store/actions';
import { StoreContext } from '../../store';
import { predictionGetUserInfo } from '../../utils/api/prediction';
import dictionary from '../../utils/dictionary';

const Wrap = styled.div`
  width: max-content;
  height: 100%;
  margin-left: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  & .title {
    display: flex;

    & > span {
      opacity: 0.5;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #d4daf8;
      text-transform: uppercase;
    }
  }

  & .value {
    display: flex;

    & > span {
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ffd10a;
    }
  }
`;

const Bonuses = ({ className = '' }) => {
  const [state, dispatch] = useContext(StoreContext);
  const {
    user: { bonuses },
  } = state;

  useEffect(() => {
    if (bonuses) return () => {};

    predictionGetUserInfo()
      .then((json) => {
        if (!json) setBonuses(dispatch, 0);

        const { v_bonuses } = json;
        setBonuses(dispatch, v_bonuses || 0);
      })
      .catch((err) => {
        console.error('Bonuses request: ', err);
      });
  }, []);

  return (
    <Wrap className={className}>
      <div className="title">
        <span>{dictionary.bonuses}</span>
      </div>
      <div className="value">
        <span>{bonuses || '0.00'}</span>
      </div>
    </Wrap>
  );
};

export default Bonuses;
