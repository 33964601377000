import React, { useContext, useEffect, useMemo } from 'react';
import styled from '@emotion/styled';
import { StoreContext } from '../../store';
import { appIsLoaded, currEnvIsDev, setIsMobile, setUser } from '../../store/actions';
import { breakpoints } from '../../utils/style';
import { isDevDomain, isMobile } from '../../utils/system';
import MainMenu from '../Menus/MainMenu';
import Contacts from './Contacts';
import BottomPanel from './mobile/BottomPanel';
import { getUserName, isToken, isUserName } from '../../utils/api';

const Header = styled.header`
  height: 100%;
  position: relative;
  padding: 0 16px;
  & > .full-size-bg {
    display: none;
  }
  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    background-color: #00011c;
    position: sticky;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 70px;
    & > .full-size-bg {
      display: block;
      position: absolute;
      top: 0;
      left: -17px;
      width: 100vw;
      height: 100%;
      background-color: #00011c;
    }
    &.open {
      width: 100vw;
    }
  }
`;

export default ({ location }) => {
  const [state, dispatch] = useContext(StoreContext);
  const {
    application: { isLoaded },
  } = state;

  useEffect(() => {
    if (window && !state.application.isLoaded) {
      const domain = isDevDomain();
      // First render indicator. After it's fired - code is firing on client side.
      appIsLoaded(dispatch);

      // Setup current environment is production(?) (boolean) into storage.
      currEnvIsDev(dispatch, domain);

      // Clear console in prod.
      if (!domain) console.clear(); // eslint-disable-line no-console
    }
  }, []);

  const resize = () => setIsMobile(dispatch, isMobile());

  // For already loaded application
  useEffect(() => {
    if (!state.application.isLoaded) return () => {};

    // Set authenticated user
    if (isUserName() && isToken() && !state.user.userName) setUser(dispatch, getUserName());
    // Set isMobile flag + start event for resize tracking
    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [isLoaded]);

  const Result = useMemo(
    () => (
      <Header className="header" id="header">
        <Contacts />
        <MainMenu isHome={location.pathname === '/'} />
        <BottomPanel />
        <div className="full-size-bg" />
      </Header>
    ),
    [location],
  );

  return Result;
};
