import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { color, flex, formsAutoFillingStyles, formStyles, resetButton } from '../../../utils/style';
import PopupWindow from '../../shared/PopupWindow';
import Star from './StarIcons';
import { withdrawRequestUrl } from './Config';
import StoreContext from '../../shared/Context/StoreContext';
import { getAuthToken, getAuthHeader, Logout } from '../../Auth/Utils';
import dictionary from '../../../utils/dictionary';

const Wrapper = styled('div')`
  ${formStyles};
  ${formsAutoFillingStyles};
  position: relative;
  padding: 0 0 20px;

  .loading {
    position: relative;
  }

  .loading::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${color.black};
    opacity: 0.7;
    z-index: 1;
  }

  .two-fields {
    .fieldWrap {
      width: 48%;
      display: inline-block;
      vertical-align: top;
    }
  }

  .red-button {
    float: right;
  }

  .green-button {
    margin: 0;
  }

  .labelField {
    label {
      font-family: Kanit;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: rgba(255, 255, 255, 0.5);
    }

    & input[type='text'],
    & textarea {
      font-family: Kanit;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: rgba(255, 255, 255, 1);

      border-radius: 4px;
      border: solid 1px rgba(255, 255, 255, 0.4);

      &::placeholder {
        font-family: Kanit;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.14;
        letter-spacing: normal;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    & textarea {
      padding-top: 10px;
      padding-right: 16px;
      height: 150px;
      resize: none;
    }

    & .not-valid-error {
      border: solid 1px #fb0250 !important;
    }

    .fieldWrap .error {
      top: 43px;
    }
  }

  & button {
    ${resetButton};
    width: 318px;
    height: 44px;
    border-radius: 4px;
    background-color: #fb0250 !important;
    font-family: Kanit;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fbfcfd;

    &:hover {
      background-color: rgba(251, 2, 80, 0.79) !important;
    }

    &:active {
      background-color: rgba(251, 2, 80, 1) !important;
    }

    &:disabled {
      opacity: 0.6;

      &:hover,
      &:active {
        background-color: #fb0250 !important;
      }
    }
  }

  .rating {
    float: left;
  }
  .rating input {
    display: none;
  }

  .rating label {
    display: inline-block;
    cursor: pointer;
    color: ${color.midGrey};
    float: right;
    font-size: 30px;
    height: 40px;
    width: 40px;
    margin: 0;
    position: relative;
  }
`;

const StarFieldset = styled('div')`
  border: none;
  float: left;
  padding: 20px 0 10px;
  legend {
    color: ${color.textGrey};
  }
  label {
    width: 36px;
    height: 36px;
    display: inline-block;
    border: 1px solid ${color.midGrey};
    text-indent: -9999px;
    overflow: hidden;
    border-radius: 20px;
    margin-right: 5px;
    cursor: pointer;
    padding: 0 !important;
    float: left;
    &:hover {
      border-color: ${color.white};
    }
  }

  input[type='radio']:checked + label {
    border-color: ${color.yellow};
  }

  input[type='radio'] {
    display: none;
  }
`;

const AllStarWrapper = styled('div')`
  position: relative;
  height: 36px;
  width: 205px;

  & label {
    border: none;
    background-color: rgba(255, 255, 255, 0.08);
  }
`;

const StarInputs = styled('div')`
  position: relative;
  z-index: 1;

  & .checked {
    & + label {
      background-color: rgba(255, 255, 255, 0.2) !important;
    }
  }
`;

const StarContainer = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  height: 36px;
  width: 195px;
  padding-left: 5px;

  ${flex};
  justify-content: space-between;

  & .star-holder {
    ${flex};
    pointer-events: none;
  }
`;

const MsgWrap = styled('div')`
  margin-bottom: 60px;
  span {
    display: none;
    background-color: ${color.yellow};
    color: ${color.black};
    padding: 5px 10px;
    text-align: center;
    &.visible {
      display: block;
    }
  }
  h2,
  a {
    color: orange;
  }
  a {
    cursor: pointer;
  }
  &.successMsg {
    display: none;
    &.visible {
      display: block;
      & + .game-withdraw-form,
      & + .withdraw-popup-window-title {
        display: none;
      }
    }
  }
`;

// REFACTOR
// Need to rewrite everything where 'visible' is toggling.
const showErrorMsg = (msg) => {
  const errorMsg = document.getElementById('withdrawErrorMsg');
  errorMsg.innerText = msg;
  errorMsg.classList.add('visible');
};

const showSuccessMsg = () => {
  const errorMsg = document.getElementById('withdrawErrorMsg');
  if (errorMsg) {
    errorMsg.classList.remove('visible');
  }

  const successMsg = document.getElementById('withdrawSuccessMsg');
  if (successMsg) {
    successMsg.classList.add('visible');
  }

  const popupTitle = document.getElementById('withdraw-popup-window-title');
  if (popupTitle) {
    popupTitle.style.display = 'none';
  }
};

// Radio input
const RadioButton = ({ field: { name, value, onChange, onBlur }, id, label, ...props }) => (
  <>
    <input
      name={name}
      id={id}
      type="radio"
      value={id} // could be something else for output?
      checked={id === value}
      onChange={onChange}
      // onBlur={onBlur}
    />
    <label htmlFor={id}>
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30">
        <path
          fill="none"
          stroke="#27AEEE"
          d="M17.448 1.89l3.384 6.77c.235.471.69.798 1.217.873l7.566 1.086c1.325.19 1.854 1.798.895 2.72l-5.475 5.271c-.38.367-.554.895-.464 1.412l1.292 7.442c.227 1.304-1.159 2.297-2.344 1.682l-6.767-3.513c-.47-.244-1.033-.244-1.504 0l-6.767 3.513c-1.185.616-2.57-.378-2.344-1.682l1.292-7.442c.09-.517-.084-1.045-.464-1.412L1.49 13.34c-.96-.923-.43-2.532.895-2.721l7.566-1.086c.527-.075.982-.402 1.217-.873l3.384-6.77c.592-1.186 2.304-1.186 2.896 0z"
        />
      </svg>
    </label>
  </>
);

// Radio group
const RadioButtonGroup = ({ label, children }) => (
  <StarFieldset className="emoji">
    <legend>{label}</legend>
    <AllStarWrapper>
      <StarContainer>
        <Star text1="smiley" text2="crazy" text3="cowboy" text4="devil" text5="love" />
      </StarContainer>
      <StarInputs>{children}</StarInputs>
    </AllStarWrapper>
  </StarFieldset>
);

const StarFieldsetStars = styled('div')`
  border: none;
  padding: 0 0 20px;
  clear: left;
`;

// Radio group
const RadioButtonGroupStars = ({ label, children }) => (
  <StarFieldsetStars className="rating">
    <legend>{label}</legend>
    {children}
  </StarFieldsetStars>
);

const initialState = {
  username: '',
  comment: '',
  emojis: '',
  // stars: '',
};

export const FormBody = () => {
  const StoreContextValue = useContext(StoreContext);
  const { setUsernameCallback } = StoreContextValue;

  const onSubmit = async (values, { resetForm }) => {
    if (!getAuthToken) {
      return false;
    }
    const body = JSON.stringify(values);
    const response = await fetch(withdrawRequestUrl, {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthHeader(),
      },
    });
    if (response.ok) {
      const json = await response.json();
      if (json.error && json.message) {
        showErrorMsg(json.message);
        resetForm({});
        if (json.error_type === 'session_expired') {
          Logout(false, setUsernameCallback);
        }
      } else {
        showSuccessMsg();
        resetForm({});
      }
    } else {
      // console.log('Withdraw Error HTTP: ' + response.status);
      // need to implement error log without console

      const json = await response.json();
      if (json.code === 'jwt_auth_invalid_token') {
        Logout(false, setUsernameCallback);
      }
    }

    return false;
  };

  const radioArray = [
    {
      text: 'smiley',
      RadioComponent: RadioButton,
      label: '5',
    },
    {
      text: 'crazy',
      RadioComponent: RadioButton,
      label: '4',
    },
    {
      text: 'cowboy',
      RadioComponent: RadioButton,
      label: '3',
    },
    {
      text: 'devil',
      RadioComponent: RadioButton,
      label: '2',
    },
    {
      text: 'love',
      RadioComponent: RadioButton,
      label: '1',
    },
  ];
  return (
    <Wrapper>
      <MsgWrap id="loginMsg-form">
        <span className="errorMsg" id="withdrawErrorMsg">
          การส่งแบบฟอร์มล้มเหลว กรุณาลองอีกครั้ง
        </span>
      </MsgWrap>
      <MsgWrap className="successMsg a-center" id="withdrawSuccessMsg">
        <h2>ติดต่อ Call Center</h2>
        <p>ระบบได้รับความคิดเห็นของคุณแล้ว</p>
        <p>กรุณาติดต่อ Call Center เพื่อรับเงินได้ที่</p>
        <p>
          <a href={dictionary.lineLink} target="_blank" rel="noopener noreferrer" aria-label="Call">
            Link
          </a>
        </p>
      </MsgWrap>
      <Formik
        initialValues={initialState}
        onSubmit={onSubmit}
        validationSchema={Yup.object().shape({
          username: Yup.string().required('จำเป็นต้องใช้'),
          comment: Yup.string().min(100, 'อย่างน้อย 100 ตัวอักษร').required('จำเป็นต้องใช้'),
          emojis: Yup.string().required('จำเป็นต้องใช้'),
          // stars: Yup.string().required('จำเป็นต้องใช้'),
        })}
        validate={(values) => {
          console.log(' --- --- --- --- --- --- --- --- ---');
          for (const [key, value] of Object.entries(values)) {
            console.log(key, ' ----> ', value);
          }
          console.log(' --- --- --- --- --- --- --- --- ---');
        }}
      >
        {({ errors, status, touched, isSubmitting, isValid, values }) => (
          <Form id="withdrawForm" className="game-withdraw-form">
            {/* Username */}
            <div className="labelField">
              <label htmlFor="username">ยูสเซอร์เนม</label>
              <div className="fieldWrap iconField">
                {errors.username && touched.username && <div className="error-new">{errors.username}</div>}
                <Field
                  type="text"
                  name="username"
                  className={`username ${errors.username && touched.username ? 'not-valid-error' : ''}`}
                  placeholder="ยูสเซอร์เนม (e.g. AOOG000)"
                  value={values.username || ''}
                />
              </div>
            </div>

            {/* Comment */}
            <div className="labelField">
              <label htmlFor="comment">ความคิดเห็นของคุณ</label>
              <div className="fieldWrap iconField">
                {errors.comment && touched.comment && <div className="error-new">! {errors.comment}</div>}
                <pre>{values.comment}</pre>
                <Field
                  component="textarea"
                  id="comment"
                  name="comment"
                  placeholder="คิดเห็น"
                  value={values.comment || ''}
                  className={errors.comment && touched.comment && 'not-valid-error'}
                />
              </div>
            </div>

            {/* Emoji */}
            <RadioButtonGroup id="emojis" label="ความรู้สึกของคุณ" value={values.emojis || ''} error={errors.emojis}>
              {radioArray?.map(({ RadioComponent, label, text }) => (
                <Field name="emojis" key={`star${label}key`} component={RadioComponent} id={text} label={label} />
              ))}
            </RadioButtonGroup>
            {errors.emojis && touched.emojis && <div className="error">! {errors.emojis}</div>}

            {/* /!* Stars *!/ */}
            <RadioButtonGroupStars
              id="stars"
              value={values.rating}
              error={errors.radioGroup}
              touched={touched.radioGroup}
            >
              {radioArray?.map(({ RadioComponent, label }) => (
                <Field
                  name="stars"
                  component={RadioComponent}
                  key={`star${label}key`}
                  id={`star${label}`}
                  label={`${label} star`}
                />
              ))}
            </RadioButtonGroupStars>

            {status && status.msg && <div>{status.msg}</div>}
            <div className="clear">
              <button className="button transparent-button" type="submit" disabled={isSubmitting || !isValid}>
                ส่งความคิดเห็นและรับเงิน
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Wrapper>
  );
};

const WithdrawForm = ({ popupId }) => (
  <PopupWindow title="ถอนเงิน" popupId={popupId} extraClass="a-left">
    <FormBody popupId={popupId} />
  </PopupWindow>
);

export default WithdrawForm;
