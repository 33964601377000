import React from 'react';
import Plx from 'react-plx';
import styled from '@emotion/styled';
import ball_1 from '../../img/parallax/ball_1.png';
import ball_2 from '../../img/parallax/ball_2.png';
import ball_3 from '../../img/parallax/ball_3.png';
import light_left from '../../img/parallax/light_left.png';
import light_right from '../../img/parallax/light_right.png';
import { breakpoints } from '../../utils/style';

const ParallaxContainer = styled('div')`
  position: relative;
  z-index: -100;
  .bg {
    height: 310px;
    width: 310px;
    position: fixed;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .ball_1 {
    background-image: url(${ball_1});
    top: 150px;
    left: 130px;
    @media (max-width: ${breakpoints.rt}) {
      top: 150px;
      left: -120px;
      height: 300px;
      width: 300px;
    }
    @media (max-width: ${breakpoints.xs}) {
      left: -120px;
      height: 250px;
      width: 250px;
    }
  }
  .ball_2 {
    background-image: url(${ball_2});
    top: 650px;
    right: 50px;
    height: 450px;
    width: 450px;
    @media (max-width: ${breakpoints.rt}) {
      top: 400px;
      right: -120px;
      height: 350px;
      width: 350px;
    }
    @media (max-width: ${breakpoints.xs}) {
      top: 310px;
      right: -100px;
      height: 300px;
      width: 300px;
    }
  }
  .ball_3 {
    background-image: url(${ball_3});
    top: 500px;
    left: 0px;
    height: 950px;
    width: 950px;
    @media (max-width: ${breakpoints.rt}) {
      top: 350px;
      left: -100px;
      height: 750px;
      width: 750px;
    }
    @media (max-width: ${breakpoints.xs}) {
      top: 630px;
      left: -100px;
      height: 650px;
      width: 650px;
    }
    @media (max-width: ${breakpoints.xxs}) {
      top: 400px;
      display: block;
    }
  }
  .light_left {
    background-image: url(${light_left});
    top: 650px;
    left: -550px;
    height: 1150px;
    width: 1150px;
    @media (max-width: ${breakpoints.rt}) {
      top: 573px;
      left: -429px;
      height: 950px;
      width: 950px;
    }
    @media (max-width: ${breakpoints.md}) {
      display: none;
    }
  }
  .light_right {
    background-image: url(${light_right});
    top: 50px;
    right: -380px;
    height: 950px;
    width: 950px;
    @media (max-width: ${breakpoints.md}) {
      display: none;
    }
  }
`;

const ParallaxHome = () => {
  const image1ParallaxData = [
    {
      start: -100,
      duration: 7550,
      properties: [
        {
          startValue: 200,
          endValue: -400,
          property: 'translateY',
        },
      ],
    },
  ];

  const image3ParallaxData = [
    {
      start: -100,
      duration: 13550,
      properties: [
        {
          startValue: 200,
          endValue: -400,
          property: 'translateY',
        },
      ],
    },
  ];

// REFACTOR
// One more component for parallax. Should be refactored as one of the tasks.
  return (
    <div>
      <ParallaxContainer>
        <Plx className="bg ball_1" parallaxData={image1ParallaxData} />
        <Plx className="bg ball_2" parallaxData={image1ParallaxData} />
        <Plx className="bg ball_3" parallaxData={image3ParallaxData} />
      </ParallaxContainer>
    </div>
  );
};

export default ParallaxHome;
