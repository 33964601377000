import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Menu from './index';

// Small refactor
// Create one time useStaticQuery for every same component instead of writing all time 30 lines for the same thing.
export default () => (
  <StaticQuery
    query={graphql`
      {
        allWordpressWpApiMenusMenusItems(filter: { slug: { eq: "footer-submenu" } }) {
          nodes {
            id
            name
            slug
            items {
              type_label
              title
              object_slug
              url
              path
            }
          }
        }
      }
    `}
    render={(data) => <Menu data={data} />}
  />
);
