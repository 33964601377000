import React from 'react';

const ProfileSvg = ({ width = 28, height = 28 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 28">
    <g fill="none" fillRule="evenodd">
      <circle cx="14" cy="14" r="14" fill="#374378" />
      <g>
        <path
          fill="#2DA5FE"
          fillRule="nonzero"
          d="M12 10.5c1.998 0 3.64 1.579 3.745 3.551l.005.199V15c0 .778-.596 1.42-1.356 1.493l-.144.007H3.75c-.778 0-1.42-.596-1.493-1.356L2.25 15v-.75c0-1.998 1.579-3.64 3.551-3.745L6 10.5h6zm0 1.5H6c-1.184 0-2.165.931-2.245 2.097l-.005.153V15h10.5v-.75c0-1.184-.931-2.165-2.097-2.245L12 12zM9 1.5c2.265 0 4.112 1.847 4.112 4.112 0 2.268-1.85 4.138-4.112 4.138-2.265 0-4.112-1.847-4.112-4.112C4.888 3.37 6.738 1.5 9 1.5zM9 3C7.57 3 6.388 4.195 6.388 5.638 6.388 7.074 7.564 8.25 9 8.25c1.43 0 2.612-1.195 2.612-2.638C11.612 4.176 10.436 3 9 3z"
          transform="translate(5 5)"
        />
        <path d="M0 0L18 0 18 18 0 18z" transform="translate(5 5)" />
      </g>
    </g>
  </svg>
);

export default ProfileSvg;
