import React, { useEffect, useContext } from 'react';

import styled from '@emotion/styled';
import IcomoonIcon from '../IcomoonIcon';
import { breakpoints, color } from '../../../utils/style';

import { StoreContext } from '../../../store';
import {
  setNewPopup, removePopup,
  showPopup, hidePopup,
} from '../../../store/actions';

const Wrap = styled('div')`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7) !important;
  z-index: 999999!important;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  
  // Withdraw modifier
  &#withdraw-popup-id {
    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  
    & > div {
      width: 80%;
      min-height: 93%;
      
      &::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    &#withdraw-popup-id {
      overflow-y: scroll;
      align-items: flex-start;
      padding: 30px 0;
      
      & > div {
        width: 90%;
        max-height: max-content;
      }
    }
  }
`;

const Popup = styled('div')`
  width: 494px;
  height: 396px;
  z-index: 999999;
  padding: 30px;
  background: #10192e;
  border-radius: 8px;
  overflow-y: auto;
  background-size: 100% 100%;
  position: relative;

  &#home-video-popup {
    width: 900px;
    height: auto;
  }
  
  .h3 {
    margin: 0;
  }
  
  p {
    margin: 10px 0;
  }
  
  & .popup-content {
    overflow: hidden;
    margin-top: 10px;
  }
  
  &.popup-open {
    display: block;
    -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  
  & iframe {
    width: 100%;
    height: 540px;
  }
  
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
      transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    width: calc(100% - 32px);
    padding: 16px;
    
    & iframe {
      height: 200px;
    }

    &#home-video-popup {
      width: 900px;
      height: auto;

      & iframe {
        height: 300px;
      }
    }
    
    &#free-spin-home-popup {
      left: calc(50% - 150px);
      width: 300px;
      height: 281px;
    }
  }
  
  @media (max-width: ${breakpoints.md}) {
    @media (orientation: landscape) {
      width: 80%;
      padding: 20px 10px 10px 10px;
    
      & iframe {
        height: 100%;
      }

      &#home-video-popup {
        & iframe {
          height: 65vh;
        }
      }
    }
  }
`;

const CloseButton = styled('button')`
  background: none;
  border: none;
  outline: none;
  color: #fff;
  position: absolute;
  top: 32px;
  right: 24px;
  z-index: 1;
  
  &:hover,
  &:active,
  &:focus {
    opacity: 0.7;
    cursor: pointer;
  }
  
  @media (max-width: ${breakpoints.xs}) {
    top: 25px;
    right: 15px;
  }
`;

const useModalOpen = (popupId) => {
  const [state, dispatch] = useContext(StoreContext);
  return (event) => {
    if (event) event.preventDefault();
    showPopup(dispatch, popupId);
  };
};
const useModalClose = (popupId, onClose) => {
  const [state, dispatch] = useContext(StoreContext);
  return (event) => {
    if (event) event.preventDefault();
    if (onClose) onClose(popupId);
    hidePopup(dispatch, popupId);
  };
};

const Modal = ({
  children, title, popupId,
  onClose,
}) => {
  const [state, dispatch] = useContext(StoreContext);
  const { popup } = state;

  useEffect(() => {
    setNewPopup(dispatch, popupId);
    return () => { removePopup(dispatch, popupId); };
  }, []);
  const closeHandler = useModalClose(popupId, onClose);

  if (!popup[popupId]) return null;

  return (
    <Wrap
      id={popupId ? `${popupId}-id` : 'popWrapper'}
      className="PopWrapper"
    >
      <Popup id={popupId} className="popup-window">
        <CloseButton onClick={closeHandler}>
          <IcomoonIcon icon="close" color={color.white} size={30} />
        </CloseButton>
        {title && <div id={`${popupId}-title`} className="h3" dangerouslySetInnerHTML={{ __html: title }} />}
        <div id={`${popupId}-content`} className="popup-content">
          {children}
        </div>
      </Popup>
    </Wrap>
  );
};

export default Modal;

export { useModalOpen, useModalClose };
