import React from 'react';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';
import { breakpoints, flex } from '../../../utils/style';
import { getFile } from '../../../utils/system';
import Coin from '../../../icons/svg/Coin';
import WithdrawForm from '../../Auth/Form/WithdrawForm';
import Modal from '../../shared/Modal';

const Wrapper = styled('div')`
  width: 100%;
  height: max-content;
  background-color: #0d182c;
  border-radius: 8px;

  ${flex};

  & .banner-wd {
    width: 55%;
    height: 100%;
    position: relative;

    ${flex};

    & .gatsby-image-wrapper {
      width: 85%;
    }

    & .title {
      position: absolute;
      top: 30px;
      left: 37px;

      width: 100%;

      ${flex};
      justify-content: flex-start;

      & > span {
        margin-left: 40px;
        font-family: Kanit;
        font-size: 44px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #2da5fe;
      }
    }
  }

  & .form {
    width: 45%;
    height: 100%;
    background-color: #141d44;
    border-radius: 0 8px 8px 0;
    padding: 0 40px 30px 40px;
  }

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    height: max-content;
    background-color: transparent;

    & .banner-wd {
      width: 100%;

      & .gatsby-image-wrapper {
        width: 100%;
      }

      & .title {
        left: 16px;
        top: 40px;

        & > svg {
          width: 40px;
          height: 40px;
        }

        & > span {
          font-family: Kanit;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.17;
          letter-spacing: normal;
          color: #2da5fe;
          margin-left: 15px;
        }
      }
    }

    & .form {
      width: 100%;
      margin-top: 25px;
      padding: 28px 14px 26px 14px;
    }
  }
`;

const WithdrawPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      wordpressPage(slug: { eq: "withdraw" }) {
        wordpress_id
        title
        path
        yoast_meta {
          yoast_wpseo_title
          yoast_wpseo_metadesc
        }
        featured_media {
          alt_text
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 550) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

  const { wordpressPage: withdrawPage } = queryData;
  const { featured_media } = withdrawPage;
  const banner = featured_media;

  return (
    <Wrapper>
      <div className="banner-wd">
        {getFile(banner, 'fluid') && (
          <Img alt={banner.alt_text} fluid={getFile(banner, 'fluid')} fadeIn={false} loading="eager" />
        )}
        <div className="title">
          <Coin />
          <span>ถอนเงินหมุนฟรี</span>
        </div>
      </div>
      <div className="form">
        <WithdrawForm />
      </div>
    </Wrapper>
  );
};

export const WithdrawPopup = (props) => (
  <Modal {...props}>
    <WithdrawPage />
  </Modal>
);

export default WithdrawPage;
