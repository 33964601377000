import React, { useState } from 'react';
import styled from '@emotion/styled';
import { breakpoints, color } from '../../../utils/style';
import FreshChatBtnIcon from './fresh-chat/chat-btns/FreshChatBtnIcon';

const Wrap = styled('div')`
  .live-chat-btn {
    bottom: 10px;
    position: fixed;
    right: 10px;
    background-color: ${color.blueBtn};
    color: ${color.black};
    padding: 5px 30px;
    font-size: 1rem;
    font-weight: bold;
    line-height: 35px;
    cursor: pointer;
    outline:none;
    border:none;
    border-radius: 25px;
    text-transform: uppercase;
    svg {
      margin-right: 10px;
    }
  }
  
  &.open {
    bottom: 0;
    .live-chat-btn {
      display: none;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const RespondioChatComponent = () => {
  const [chatLoaded, setChatLoaded] = useState(false);
  const CHAT_SCRIPT_URL = "https://cdn.respond.io/webchat/widget/widget.js?cId=3e3e44cc1f52688b8d0a9e227eb8559";

  const showHideChat = () => {
    const head = document.head;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = CHAT_SCRIPT_URL;
    script.id = "respondio__widget";

    const chatScript = document.getElementById("respondio__widget");
    if (chatScript === null) {
      head.appendChild(script);
      setChatLoaded(true);
    }
  }

  return (
    <Wrap className={chatLoaded ? "open" : "closed"}>
      <button className="live-chat-btn" onClick={showHideChat}>
        <FreshChatBtnIcon color={color.darkGrey} size={16} />
        <span>แชทสด</span>
      </button>
    </Wrap>
  );
};
export default RespondioChatComponent;
