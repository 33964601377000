/* eslint-disable max-len */
import React from 'react';
import styled from "@emotion/styled";

const SvgWrap = styled("div")`
  .st0{
    fill-rule:evenodd;
    clip-rule:evenodd;
    fill:#F7D149;
  }
  .st1{
    fill:#F7D149;
  }
`;

const LogoIcon = () => (
  <SvgWrap>
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
         width="269" height="42" viewBox="0 0 269 42" style={{enableBackground: "new 0 0 269 42"}} xmlSpace="preserve">
      <path className="st0" d="M134.3,0l13.2,42h-4L130.3,0H134.3z M143.8,0L157,42h-4L139.8,0H143.8z M247.3,0c4.9,0,9.5,1.7,13.2,4.7
	c2.9,2.5,5.1,5.8,6.3,9.3l0.2,0.4h-19.6c-3.4-0.1-6.4,2.5-6.7,6c-0.4,3.5,2.2,6.7,5.7,7.2h0.2h10.2c-0.7,0.9-1.4,1.8-2.3,2.4
	c-2.1,1.7-4.6,2.6-7.2,2.6c-6.4,0-11.6-5.2-11.6-11.6c0-0.4,0-0.8,0.1-1.2c0.6-5.9,5.5-10.4,11.4-10.4h0.3h10.8
	c-0.3-0.3-0.6-0.6-0.9-0.8C254.6,6.3,251,5,247.3,5s-7.2,1.3-10,3.6c-3,2.4-5.1,6-5.7,9.8c-1.4,8.6,4.5,16.9,13.1,18.2
	c8.6,1.4,16.7-4.4,18.2-12.9l0.1-0.3h-15.6c-1.4,0-2.5-1.1-2.5-2.5c0-1.3,1.1-2.5,2.4-2.6h0.2l3.9,0.1l17.3,0.1
	c0,6.7-2.7,14.4-8,18.8c-3.7,3-8.3,4.7-13.1,4.7c-11.6,0-21-9.4-21-21c0.1-0.7,0.1-1.5,0.2-2.2C227.7,8.1,236.7,0,247.3,0L247.3,0z
	 M25.7,23.9c1,0,1.9,0.1,2.7,0.2c0.8,0.1,1.5,0.4,2.1,0.7c0.6,0.3,1,0.7,1.4,1.1c0.4,0.4,0.6,0.9,0.8,1.3c0.2,0.5,0.3,1.1,0.3,1.9
	h-6c0-0.6-0.2-1-0.4-1.2c-0.2-0.2-0.6-0.3-1.1-0.3s-0.9,0.1-1.1,0.3c-0.3,0.2-0.4,0.5-0.4,0.8s0.1,0.5,0.3,0.7
	c0.2,0.2,0.9,0.4,2,0.7c0.4,0.1,1.2,0.3,2.2,0.6c1,0.3,1.9,0.6,2.4,0.9c0.6,0.3,1.1,0.7,1.4,1.1s0.6,0.8,0.8,1.4
	c0.2,0.5,0.3,1.1,0.3,1.8c0,1.2-0.3,2.3-0.8,3.2c-0.6,0.9-1.4,1.6-2.7,2.1S27.4,42,25.6,42c-1.1,0-2.1-0.1-2.9-0.2s-1.6-0.4-2.2-0.7
	S19.4,40.4,19,40c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.5-0.3-1.3-0.4-2.2H24c0,0.7,0.1,1.2,0.4,1.5s0.7,0.4,1.3,0.4c0.5,0,0.9-0.1,1.2-0.4
	c0.3-0.3,0.5-0.6,0.5-1c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.7-0.4-1.5-0.6l-2.5-0.7c-1.3-0.4-2.2-0.7-2.7-0.9s-0.9-0.5-1.3-0.9
	s-0.7-0.9-0.9-1.4c-0.2-0.5-0.3-1.1-0.3-1.8c0-1,0.2-1.9,0.6-2.6s1-1.3,1.7-1.8s1.6-0.8,2.5-1C23.7,24,24.7,23.9,25.7,23.9
	L25.7,23.9z M193.6,0.3v11.9h-16.7c-1.1,0-1.9,0.9-1.9,1.9c0,1.1,0.9,1.9,1.9,1.9h7.4c5.1,0,9.3,4.2,9.3,9.3V29c0,7.2-5.8,13-13,13
	h-17.2l-3.8-11.9h20.6c1.2,0,2.1-0.9,2.1-2.1s-0.9-2.1-2.1-2.1h-7.4c-5.2,0-9.5-4.2-9.5-9.5v-3.3c0-7,5.7-12.8,12.8-12.8L193.6,0.3
	L193.6,0.3z M65.8,23.9c1.4,0,2.6,0.2,3.6,0.6c1.1,0.4,1.9,1,2.6,1.7s1.2,1.5,1.5,2.3c0.5,1.3,0.8,2.7,0.8,4.4
	c0,1.4-0.2,2.6-0.6,3.7c-0.4,1.1-0.9,2.1-1.7,2.9c-0.8,0.8-1.7,1.4-2.8,1.8c-1,0.5-2.2,0.7-3.6,0.7s-2.7-0.2-3.9-0.7
	c-0.9-0.4-1.7-0.9-2.4-1.6c-0.7-0.7-1.2-1.5-1.6-2.4c-0.5-1.2-0.8-2.7-0.8-4.2c0-1.4,0.2-2.7,0.6-3.9c0.3-0.9,0.7-1.7,1.3-2.4
	c0.6-0.7,1.2-1.2,1.9-1.6s1.5-0.7,2.3-0.9C63.9,24.1,64.9,23.9,65.8,23.9z M225.8,12.4c0,9-7.6,8.6-5.6,9c2.7,0.5,6.1,2.6,6.1,9.8
	c0,8.8-8.8,10.6-12.1,10.7h-17.8V0.2h18.4C215.2,0.2,225.8,0,225.8,12.4L225.8,12.4z M47.3,24.4c2,0,3.5,0.4,4.4,1.2
	s1.3,1.9,1.3,3.2c0,0.5-0.1,1-0.2,1.5c-0.1,0.4-0.3,0.8-0.6,1.1c-0.3,0.3-0.6,0.5-1,0.7c-0.3,0.1-0.7,0.3-1.3,0.5
	c0.8,0.1,1.5,0.4,2,0.8c0.6,0.4,1,0.9,1.3,1.4c0.3,0.6,0.4,1.2,0.4,1.9s-0.1,1.3-0.4,1.8s-0.6,1-0.9,1.4c-0.4,0.4-0.8,0.7-1.3,0.9
	s-1.1,0.4-2,0.6c-0.6,0.1-1.4,0.2-2.3,0.2h-9V24.4C37.6,24.4,47.3,24.4,47.3,24.4z M44.5,34.5h-0.7v3.4h0.7c0.7,0,1.3-0.1,1.6-0.2
	s0.6-0.3,0.7-0.6c0.2-0.3,0.2-0.6,0.2-1c0-0.5-0.2-0.9-0.5-1.2C46.1,34.6,45.5,34.4,44.5,34.5L44.5,34.5z M65.7,28.4
	c-0.4,0-0.7,0.1-1,0.4c-0.3,0.2-0.5,0.6-0.6,1.2c-0.1,0.5-0.2,1.4-0.2,2.7c0,2.1,0.2,3.4,0.6,4.1c0.3,0.4,0.6,0.6,1.2,0.6
	c0.4,0,0.8-0.1,1.1-0.4c0.3-0.2,0.5-0.7,0.6-1.4s0.2-1.7,0.2-3.2c0-1.3-0.1-2.2-0.2-2.7s-0.3-0.9-0.6-1.1
	C66.4,28.5,66.1,28.4,65.7,28.4z M212.8,25.4h-6.1v6.3h6.2c0.2,0,2.4-0.1,2.4-3C215.3,25.7,212.8,25.4,212.8,25.4L212.8,25.4z
	 M44.4,28h-0.6v3.2h0.6c0.6,0,1.1-0.1,1.4-0.2c0.3-0.1,0.5-0.3,0.7-0.5s0.3-0.6,0.3-1c0-0.5-0.2-0.9-0.5-1.1
	C45.9,28.2,45.3,28,44.4,28L44.4,28z M87.5,0c1,0,1.9,0.1,2.7,0.2c0.8,0.1,1.5,0.4,2.1,0.7s1,0.7,1.4,1.1s0.7,0.9,0.8,1.3
	c0.2,0.5,0.3,1.1,0.3,1.9h-6c0-0.6-0.2-1-0.4-1.2c-0.2-0.2-0.6-0.3-1.1-0.3S86.4,3.8,86.2,4c-0.3,0.2-0.4,0.5-0.4,0.8
	c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.9,0.4,2,0.7c0.4,0.1,1.2,0.3,2.2,0.6c1,0.3,1.8,0.6,2.4,0.9s1.1,0.7,1.4,1.1s0.6,0.8,0.8,1.4
	s0.3,1.1,0.3,1.8c0,1.2-0.3,2.3-0.8,3.2c-0.6,0.9-1.4,1.6-2.7,2.1c-1.2,0.5-2.7,0.8-4.5,0.8c-1.1,0-2.1-0.1-2.9-0.2
	s-1.6-0.4-2.2-0.7c-0.6-0.3-1.1-0.7-1.5-1.1s-0.7-0.9-1-1.5c-0.2-0.5-0.3-1.3-0.4-2.2h6.4c0,0.7,0.1,1.2,0.4,1.5s0.7,0.4,1.3,0.4
	c0.5,0,0.9-0.1,1.2-0.4c0.3-0.3,0.5-0.6,0.5-1c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.7-0.4-1.5-0.6l-2.5-0.7c-1.3-0.4-2.2-0.7-2.7-0.9
	S81.1,9.4,80.7,9S80,8.1,79.8,7.6c-0.2-0.5-0.3-1.1-0.3-1.8c0-1,0.2-1.9,0.6-2.6s1-1.3,1.7-1.8c0.7-0.4,1.6-0.8,2.5-1
	C85.5,0.1,86.5,0,87.5,0L87.5,0z M259.8,15.3c0.1,0,0.3,0,0.4,0s0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.2
	c0,0.1,0,0.2,0,0.3H260c0-0.1,0-0.1-0.1-0.2c0,0-0.1,0-0.2,0s-0.1,0-0.2,0s-0.1,0.1-0.1,0.1v0.1c0,0,0.1,0.1,0.3,0.1
	c0.1,0,0.2,0,0.3,0.1c0.1,0,0.3,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2s0,0.2,0,0.2c0,0.2,0,0.3-0.1,0.4
	s-0.2,0.2-0.4,0.3c-0.2,0.1-0.4,0.1-0.6,0.1s-0.3,0-0.4,0s-0.2-0.1-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.1-0.2
	s0-0.2,0-0.3h0.9c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0.1,0.2,0.1s0.1,0,0.2-0.1L260,17v-0.1c0,0-0.1-0.1-0.2-0.1l-0.3-0.1
	c-0.2-0.1-0.3-0.1-0.4-0.1s-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.1-0.2s0-0.2,0-0.2c0-0.1,0-0.3,0.1-0.4c0.1-0.1,0.1-0.2,0.2-0.2
	s0.2-0.1,0.4-0.1C259.5,15.3,259.7,15.3,259.8,15.3L259.8,15.3z M253,15.3l0.2,1.5l0.2-1.5h1l0.2,1.5l0.2-1.5h0.8l-0.5,2.4h-1
	l-0.2-1.5l-0.2,1.5h-1l-0.5-2.4L253,15.3L253,15.3z M248.4,15.3V17h0.9v0.6h-1.8v-2.4L248.4,15.3L248.4,15.3z M251.2,15.4
	c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0.1l0.1,0.1c0,0,0,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3v1.7H251V16c0-0.1-0.1-0.2-0.3-0.2
	s-0.3,0.1-0.3,0.2v1.7h-0.8v-2.3h0.8v0.2c0.1-0.1,0.2-0.2,0.3-0.2C250.9,15.4,251.1,15.4,251.2,15.4L251.2,15.4z M257.6,15.4
	l0.8,2.4h-1l-0.1-0.4h-0.7l-0.1,0.4h-0.9l0.8-2.4H257.6L257.6,15.4z M262.3,15.4v2.4h-0.9v-2.4H262.3L262.3,15.4z M264.7,15.4
	l0.8,2.4h-1l-0.1-0.4h-0.7l-0.1,0.4h-0.9l0.8-2.4H264.7L264.7,15.4z M122.7,0.5l5.4,17h-7l-0.7-2.7h-4.9l-0.7,2.7h-6.4l5.4-17
	C114,0.5,122.7,0.5,122.7,0.5z M105.6,0.5v17h-6.7v-17H105.6L105.6,0.5z M5.5,0.5v12.4h6.1v4.6H0v-17H5.5z M26,0.5
	c0.6,0,1.1,0.1,1.6,0.2s0.9,0.3,1.2,0.6c0.3,0.2,0.6,0.5,0.8,0.8s0.3,0.8,0.5,1.3c0.1,0.4,0.1,1.1,0.1,2.1v12h-5.7v-12
	c0-0.5-0.5-1.4-1.9-1.4c-1.3,0-1.9,1-1.9,1.6v11.9H15V0.9h5.4l0.1,1.8c0.5-0.8,1.7-1.3,2.4-1.6S25.1,0.5,26,0.5L26,0.5z M38.4,0.5
	l1.5,10.7l1.6-10.7h7L50,11.2l1.5-10.7h5.9l-3.6,17h-7.2L45,6.5l-1.6,11.1H36l-3.6-17h6V0.5z M71.6,0.5l5.4,17h-7l-0.7-2.7h-4.9
	l-0.7,2.7h-6.4l5.4-17H71.6L71.6,0.5z M256.9,15.9l-0.2,0.9h0.4L256.9,15.9L256.9,15.9z M264.1,15.9l-0.2,0.9h0.4L264.1,15.9z
	 M212.8,10h-6.1v6.4h6.1c0,0,2.5,0,2.5-2.9C215.3,10.4,213.1,10,212.8,10L212.8,10z M67.1,4.2l-1.5,6.4h2.9L67.1,4.2z M118.2,4.2
	l-1.5,6.4h2.9L118.2,4.2z M92.9,24.5v4.6h-4.2v12.4H82V29.1h-4.2v-4.6H92.9z M103.1,23.8c1.4,0,2.6,0.2,3.6,0.6
	c1.1,0.4,1.9,1,2.6,1.7s1.2,1.5,1.5,2.3c0.5,1.3,0.8,2.7,0.8,4.4c0,1.4-0.2,2.6-0.6,3.7c-0.4,1.1-0.9,2.1-1.7,2.9
	c-0.8,0.8-1.7,1.4-2.8,1.8c-1,0.5-2.2,0.7-3.6,0.7s-2.7-0.2-3.9-0.7c-0.9-0.4-1.7-0.9-2.4-1.6c-0.7-0.7-1.2-1.5-1.6-2.4
	c-0.5-1.2-0.8-2.7-0.8-4.2c0-1.4,0.2-2.7,0.6-3.9c0.3-0.9,0.7-1.7,1.3-2.4c0.6-0.7,1.2-1.2,1.9-1.6c0.7-0.4,1.5-0.7,2.3-0.9
	C101.2,24,102.2,23.8,103.1,23.8z M103,28.3c-0.4,0-0.7,0.1-1,0.4c-0.3,0.2-0.5,0.6-0.6,1.2c-0.1,0.5-0.2,1.4-0.2,2.7
	c0,2.1,0.2,3.4,0.6,4.1c0.3,0.4,0.6,0.6,1.2,0.6c0.4,0,0.8-0.1,1.1-0.4c0.3-0.2,0.5-0.7,0.6-1.4c0.1-0.7,0.2-1.7,0.2-3.2
	c0-1.3-0.1-2.2-0.2-2.7s-0.3-0.9-0.6-1.1C103.7,28.4,103.4,28.3,103,28.3z"/>
      <g>
        <path className="st1" d="M114.5,41.6V24.5c0,0,7.3-0.1,9.2,0c5.4,0,6.6,3.5,6.6,5.8c0,3.2-1.4,6-9.1,6l0,0v5.4H114.5z M121.2,32.4
		c2.8,0,3.3-0.6,3.3-2.1c0-1.4-0.2-2-3.3-2l0,0V32.4L121.2,32.4z"/>
      </g>
    </svg>
  </SvgWrap>
);

export default LogoIcon;
