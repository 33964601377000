import React from 'react';
import Img from 'gatsby-image';

const Star = ({ fixed, text }) => (
  <div className="star-holder">
    {fixed ? <Img alt={text || 'star'} fixed={fixed} fadeIn={false} loading="eager" /> : <span>{text}</span>}
  </div>
);

export default Star;
