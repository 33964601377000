import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { getLocalFile } from '../../../utils/system';

const EmojiIcons = () => {
  const icons = useStaticQuery(graphql`
    query {
      star1: file(relativePath: { eq: "withdraw/star-1.png" }) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      star2: file(relativePath: { eq: "withdraw/star-2.png" }) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      star3: file(relativePath: { eq: "withdraw/star-3.png" }) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      star4: file(relativePath: { eq: "withdraw/star-4.png" }) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      star5: file(relativePath: { eq: "withdraw/star-5.png" }) {
        childImageSharp {
          fixed(width: 25) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      {
        Object.keys(icons)?.map((icon) => (
          getLocalFile(icons[icon], 'fixed') && (
            <Img alt="star1" fixed={getLocalFile(icons[icon], 'fixed')} fadeIn={false} loading="eager" />
          )))
      }
    </>
  );
};

export default EmojiIcons;
