import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { flex } from '../../utils/style';
import ProfileSvg from '../../icons/svg/ProfileSvg';
import { StoreContext } from '../../store';
import UserAvatar from '../../icons/avatar/UserAvatar';
import { setUserAvatar } from '../../store/actions';
import Loader from '../shared/Loader';

const Wrap = styled.div`
  width: max-content;
  height: max-content;
  position: relative;  
  ${flex};
  & img{
    width: 28px;
    height: 28px;
  }
`;

const ProfileIcon = ({ online = false }) => {
  const [state, dispatch] = useContext(StoreContext);
  const { userAvatar } = state.profile;

  // const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    if (!userAvatar) {
      // get user avatar - if it not exists
      setUserAvatar(dispatch);
    } else {
      // setIsLoaded(false);
    }
  }, [userAvatar]);

  return (
    <Wrap>
      {userAvatar.length === 0 ? <ProfileSvg /> : <UserAvatar avatar={userAvatar} />}
    </Wrap>
  );
};

export default ProfileIcon;
