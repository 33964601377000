import React, { useContext } from 'react';
import styled from '@emotion/styled';
import Logo from './Logo';
import Auth from '../Auth/Auth';
import { mq } from '../../utils/mediaQuery';
import phone from '../../img/tel.svg';
import { breakpoints } from '../../utils/style';
import IcomoonIcon from '../shared/IcomoonIcon';
import dictionary from '../../utils/dictionary';
import { StoreContext } from '../../store';

const MainWrapper = styled.div`
  width: 100%;
  height: 100px;
  justify-content: space-between;
  position: relative;

  ${mq('md')} {
    position: relative;
    padding-top: 20px;
  }

  @media (max-width: ${breakpoints.md}) {
    padding-top: 0;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    background-color: #141d44;
    padding: 0 16px;
  }
`;

const Contact = styled.div`
  display: flex;
  margin-right: 16px;
  background-color: #141d44;
  border-radius: 4px;
  border: none;
  padding: 0;
  text-align: center;
  align-items: center;
  height: 44px;
  font-family: 'Kanit';
  img {
    margin: 0;
  }

  ${mq('md')} {
    width: 34px;
    height: 34px;
    padding: 0;

    img {
      width: 24px;
      height: 24px;
      left: 4px;
      top: 4px;
      position: absolute;
    }
  }
`;

const Phone = styled(Contact)`
  width: 160px;
  margin-right: 16px;
  cursor: pointer;

  ${mq('md')} {
    background-color: #141d44;
  }
  :active,
  :hover {
    background-color: #1a2559;
    border: none;
  }

  @media (max-width: ${breakpoints.md}) {
    & span {
      display: none;
    }
  }
`;

const Lnwasia = styled(Contact)`
  width: 160px;
  margin-right: 16px;
  color: #00b900;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mq('md')} {
    background-color: #141d44;
  }
  :active,
  :hover {
    background-color: #1a2559;
    border: none;
  }
`;

const InlineWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 30px;

  ${mq('md')} {
    right: 0;
    top: 20px;
    width: auto;

    div {
      width: 32px;
      height: 32px;
      padding: 0;
      position: relative;

      &:first-of-type {
        margin-right: 6px;
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        width: 24px;
        height: 24px;
        margin: 0;
      }
    }
  }

  @media (max-width: ${breakpoints.md}) {
    display: none;
  }
`;

const ContactText = styled.div`
  ${mq('md')} {
    display: none;
  }
`;

const MobileLink = styled.a`
  width: 35px;

  & > svg {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export default () => {
  const [state] = useContext(StoreContext);
  const {
    user: { userName },
  } = state;

  return (
    <MainWrapper>
      <MobileLink href="/promotion/" title="โปรโมชั่น">
        <IcomoonIcon icon="promo" color="" className="" />
      </MobileLink>
      <Logo to="/" text={process.env.SITE_NAME_SHORT} title="s-bobet.net" />
      {userName ? (
        <MobileLink href="/banking/" title="การธนาคาร">
          <IcomoonIcon icon="purse-header" color="" className="" />
        </MobileLink>
      ) : (
        <MobileLink href="/sign-up-now/" title="ลงชื่อ">
          <IcomoonIcon icon="register-header" color="" className="" />
        </MobileLink>
      )}
      <InlineWrap styleProps={{ height: '100px', padding: '30px 0' }}>
        <Phone onClick={() => window.open(dictionary.phoneLink, '_blank')} elType="phone" rel="noopener noreferrer">
          <img src={phone} alt="phone" width="42" height="42" />
          <span>{dictionary.phoneNumber}</span>
        </Phone>
        <Lnwasia onClick={() => window.open(dictionary.lineLink, '_blank')} rel="noopener noreferrer">
          <IcomoonIcon icon="line-id" size={25} />
          <ContactText>{dictionary.lineWithAtSign}</ContactText>
        </Lnwasia>
      </InlineWrap>
      <Auth hideRegButton />
    </MainWrapper>
  );
};
