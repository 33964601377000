import React from 'react';
import { navigate } from 'gatsby';
import styled from '@emotion/styled';
import { mq, spreadProps } from '../../utils/mediaQuery';
import { color, breakpoints } from '../../utils/style';

const RegisterBtn = styled.div`
  height: 44px;
  background-color: ${color.darkPink};
  color: ${color.black};
  border: none;
  width: max-content;
  padding: 0 27px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${mq('md')} {
    width: calc(50% - 16px);
    margin-left: 8px;
  }

  :active,
  :hover {
    color: red;
    background-color: white;
    border: none;
  }
  ${(props) => spreadProps(props.styleProps)};

  @media (max-width: ${breakpoints.md}) {
    padding: 0 0;
  }
`;

const RegisterButton = ({ className }) => (
  <RegisterBtn className={className} onClick={() => navigate('/sign-up-now')} text="สมัครสมาชิก">
    สมัครสมาชิก
  </RegisterBtn>
);

export default RegisterButton;
