import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import { breakpoints } from '../../../utils/style';
import Burger from './Burger';
import BottomButton from './BottomButton';
import { useModalOpen } from '../../shared/Modal';
import LoginForm from '../../Auth/Form/LoginForm';
import { StoreContext } from '../../../store';
import dictionary from '../../../utils/dictionary';
import IcomoonIcon from '../../shared/IcomoonIcon';
import FreshChatMobileBtnBottom from '../../Content/Chat/fresh-chat/chat-btns/FreshChatBtnMobileBottom';

const Wrapper = styled('div')`
  width: 100%;
  height: 60px;
  background-color: #17245e;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;

  & .divider {
    height: 28px;
    width: 2px;
    background-color: #ffffff;
    opacity: 0.2;
    border-radius: 100%;
    padding-bottom: 12px;
  }

  .loader-block {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(23 36 94 / 70%);
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;

    img {
      width: 24px;
      margin: 0;
    }
  }
  
  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

const BottomPanel = () => {
  const [open, setOpen] = useState(false);
  const [state] = useContext(StoreContext);
  const {
    user: { userName },
  } = state;

  const login = useModalOpen('bottom-login-popup');


  const CHAT_SCRIPT_URL = "https://cdn.respond.io/webchat/widget/widget.js?cId=3e3e44cc1f52688b8d0a9e227eb8559";

  const showHideChat = () => {
    const head = document.head;
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = CHAT_SCRIPT_URL;
    script.id = "respondio__widget";

    const chatScript = document.getElementById("respondio__widget");
    if (chatScript === null) {
      head.appendChild(script);
    }
  };

  const toggleMenu = (e) => {
    setOpen(() => !open);
    e.preventDefault();
    const header = document.getElementById('header');
    const ul = document.getElementById('nav');
    const content = document.getElementById('all-content');
    ul.classList.toggle('open');
    content.classList.toggle('open');
    if (!open) {
      header.classList.add('open');
    } else {
      header.classList.remove('open');
    }
  };

  return (
    <Wrapper>
      <Burger onClick={toggleMenu} open={open} />
      <div className="divider" />
      {userName ? (
        <BottomButton to="/profile/">
          <IcomoonIcon icon="user-logged-in" color="" className="" size={29} />
          <span>{dictionary.profile}</span>
        </BottomButton>
      ) : (
        <BottomButton onClick={login}>
          <IcomoonIcon icon="user-login" color="" className="" size={29} />
          <span>{dictionary.login}</span>
        </BottomButton>
      )}
      <div className="divider" />
      <BottomButton to={dictionary.phoneLink} target="_blank" rel="noopener noreferrer">
        <IcomoonIcon icon="tel-bottom" color="" className="" size={29} />
        <span>โทรศัพท์</span>
      </BottomButton>
      <div className="divider" />
      <BottomButton to={dictionary.lineLink} target="_blank" rel="noopener noreferrer">
        <IcomoonIcon icon="line-bottom" color="" className="" size={29} />
        <span>Line</span>
      </BottomButton>
      <div className="divider" />
      <BottomButton onClick={showHideChat}>
        <FreshChatMobileBtnBottom />
        <span>Chat</span>
      </BottomButton>
      <LoginForm popupId="bottom-login-popup" />
    </Wrapper>
  );
};

export default BottomPanel;
