import React from 'react';

const Coin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="74"
    height="74"
    viewBox="0 0 74 74"
  >
    <defs>
      <filter id="smqsm9uuwa" width="133.3%" height="133.3%" x="-16.7%" y="-16.7%" filterUnits="objectBoundingBox">
        <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3" />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 1 0 0 0 0 0.152941176 0 0 0 0 0.407843137 0 0 0 1 0"
        />
        <feMorphology in="SourceAlpha" operator="dilate" radius=".5" result="shadowSpreadOuter2" />
        <feOffset in="shadowSpreadOuter2" result="shadowOffsetOuter2" />
        <feGaussianBlur in="shadowOffsetOuter2" result="shadowBlurOuter2" stdDeviation="2" />
        <feColorMatrix
          in="shadowBlurOuter2"
          result="shadowMatrixOuter2"
          values="0 0 0 0 1 0 0 0 0 0.152941176 0 0 0 0 0.407843137 0 0 0 1 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="shadowMatrixOuter2" />
        </feMerge>
      </filter>
      <path
        id="nuypw1s4fb"
        d="M30 0c8.017 0 15.549 3.115 21.217 8.783C56.885 14.451 60 21.983 60 30s-3.115 15.549-8.783 21.217C45.549 56.885 38.017 60 30 60s-15.549-3.115-21.217-8.783C3.115 45.549 0 38.017 0 30S3.115 14.451 8.783 8.783C14.451 3.115 21.983 0 30 0zm0 3.064C15.153 3.064 3.064 15.154 3.064 30c0 14.847 12.09 26.936 26.936 26.936 14.847 0 26.936-12.09 26.936-26.936 0-14.847-12.09-26.936-26.936-26.936zM29.513 15c.828 0 1.506.69 1.506 1.532v3.127h4.03c.83 0 1.507.69 1.507 1.532 0 .842-.678 1.531-1.507 1.531h-7.734c-1.82 0-3.302 1.507-3.302 3.357 0 1.851 1.482 3.357 3.302 3.357h4.382c3.478 0 6.303 2.872 6.303 6.42 0 3.511-2.787 6.37-6.227 6.421h-.766v3.191c0 .843-.678 1.532-1.507 1.532-.829 0-1.507-.69-1.507-1.532v-3.19h-4.168c-.829 0-1.507-.69-1.507-1.532 0-.843.678-1.532 1.507-1.532h7.872c1.82 0 3.302-1.506 3.302-3.357 0-1.851-1.481-3.357-3.302-3.357h-4.382C23.838 32.5 21 29.615 21 26.08c0-3.536 2.838-6.421 6.315-6.421h.69v-3.127c0-.843.679-1.532 1.508-1.532z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(7 7)">
      <use fill="#000" filter="url(#smqsm9uuwa)" xlinkHref="#nuypw1s4fb" />
      <use fill="#F599B5" xlinkHref="#nuypw1s4fb" />
    </g>
  </svg>
);

export default Coin;
