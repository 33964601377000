import React from 'react';

const StarsIcons = ({ active }) => (() => {
  const icons = [];

  for (let i = 0; i < 5; i++) {
    icons.push(
      <svg className={(`star${i + 1}`) === active ? 'active' : ''} xmlns="http://www.w3.org/2000/svg" width="32" height="30" viewBox="0 0 32 30">
        <path fill="none" stroke="#27AEEE" d="M17.448 1.89l3.384 6.77c.235.471.69.798 1.217.873l7.566 1.086c1.325.19 1.854 1.798.895 2.72l-5.475 5.271c-.38.367-.554.895-.464 1.412l1.292 7.442c.227 1.304-1.159 2.297-2.344 1.682l-6.767-3.513c-.47-.244-1.033-.244-1.504 0l-6.767 3.513c-1.185.616-2.57-.378-2.344-1.682l1.292-7.442c.09-.517-.084-1.045-.464-1.412L1.49 13.34c-.96-.923-.43-2.532.895-2.721l7.566-1.086c.527-.075.982-.402 1.217-.873l3.384-6.77c.592-1.186 2.304-1.186 2.896 0z" />
      </svg>,
    );
  }

  return icons;
});

export default StarsIcons;
